@use '@angular/material' as mat;

@include mat.all-component-typographies();
@include mat.core();

$mat-c2-blue: (
    100: #D9E8FF,
    200: #96BCFF,
    300: #5491FF,
    400: #5491FF,
    500: #253452,
    700: #1A5ED6,
    800: #1A5ED6,
    900: #0E4099,
    A100: #96BCFF,
    A200: #5491FF,
    A400: #253452,
    A700: #253452,
    contrast: (
        50: rgba(black, 0.87),
        100: rgba(black, 0.87),
        200: rgba(black, 0.87),
        300: rgba(black, 0.87),
        400: rgba(black, 0.87),
        500: white,
        600: white,
        700: white,
        800: rgba(white, 0.87),
        900: rgba(white, 0.87),
        A100: rgba(black, 0.87),
        A200: white,
        A400: white,
        A700: white,
    )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
$app-primary: mat.m2-define-palette($mat-c2-blue);
$app-accent: mat.m2-define-palette($mat-c2-blue);
$app-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
$general-typography: mat.m2-define-typography-config(
    $font-family: 'Assistant, sans-serif'
);

$app-theme: mat.m2-define-light-theme((
    color: (
        primary: $app-primary,
        accent: $app-accent,
        warn: $app-warn
    ),
    typography: $general-typography,
    density: 0
));

@include mat.all-component-themes($app-theme);

/* Rules for sizing the icon. */
.material-icons.md-18 {
  font-size: 18px;
}

.material-icons.md-24 {
  font-size: 24px;
}

.material-icons.md-36 {
  font-size: 36px;
}

.material-icons.md-48 {
  font-size: 48px;
}